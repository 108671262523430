<template>
    <v-container fluid>
        <v-alert
            dense
            outlined
            type="error"
            v-if="Object.keys(validation_errors).length > 0"
        >
        <ul>
            <li v-for="(errors, field) in validation_errors" :key="field">
                {{ field }}
                <ul>
                    <li v-for="(error, sl) in errors" :key="sl">
                        {{ error }}
                    </li>
                </ul>
            </li>
        </ul>
        </v-alert>
        <v-row>
            <v-col cols="12" md="4" class="py-1">
                <v-card
                    :elevation="1"
                >
                    <v-toolbar color="white" :elevation="1" height="30px">
                        <v-toolbar-title class="subtitle-2">Month Entry</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text>
                        <v-form
                            @submit.prevent="saveMonth"
                            ref="monthForm"
                            class="custom-form"
                        >
                            <v-row>
                                <v-col cols="4" class="py-1 caption"
                                    >Month</v-col
                                >
                                <v-col cols="8" class="py-0">
                                    <v-menu 
                                        max-width="290" 
                                        v-model="menu"
                                        :close-on-content-click="false"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                dense
                                                readonly
                                                outlined
                                                hide-details
                                                v-on="on"
                                                :rules="[(v) => !!v]"
                                                v-model="month.month"
                                                :error-messages="validation_errors.month"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            type="month"
                                            no-title
                                            v-model="month.month"
                                            @input="menu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 caption"
                                    >Name</v-col
                                >
                                <v-col cols="8" class="py-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        readonly
                                        :rules="[(v) => !!v]"
                                        v-model="month.name"
                                        :error-messages="validation_errors.name"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">From</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-menu 
                                        max-width="290" 
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                dense
                                                readonly
                                                outlined
                                                hide-details
                                                v-on="on"
                                                :rules="[(v) => !!v]"
                                                v-model="month.date_from"
                                                :error-messages="validation_errors.date_from"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            no-title
                                            v-model="month.date_from"
                                            @input="menu2 = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">To</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-menu 
                                        max-width="290" 
                                        v-model="menu3"
                                        :close-on-content-click="false"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                dense
                                                readonly
                                                outlined
                                                hide-details
                                                v-on="on"
                                                :rules="[(v) => !!v]"
                                                v-model="month.date_to"
                                                :error-messages="validation_errors.date_to"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            no-title
                                            v-model="month.date_to"
                                            @input="menu3 = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12" class="text-right">
                                    <v-btn
                                        type="submit"
                                        height="26px"
                                        dark
                                        color="light-blue darken-2"
                                        :elevation="1"
                                        class="mr-1"
                                        :loading="loading"
                                        >Save</v-btn
                                    >
                                    <v-btn
                                        height="26px"
                                        dark
                                        color="deep-orange"
                                        :elevation="1"
                                        @click="resetForm"
                                        >Cancel</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="8" class="py-1">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="salaryMonthHeaders"
                    :items="$store.getters['month/months']"
                    :search="searchSalaryMonth"
                >
                    <template v-slot:top>
                        <v-toolbar
                            dense
                            color="white"
                            :elevation="1"
                            height="30px"
                        >
                            <v-toolbar-title class="subtitle-2"
                                >Month List</v-toolbar-title
                            >
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Month"
                                    append-icon="mdi-magnify"
                                    style="width: 300px"
                                    v-model="searchSalaryMonth"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom v-if="$store.state['user'].role != 'User'">
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="editSalaryMonth(item)"
                                    color="primary"
                                    v-on="on"
                                    >mdi-circle-edit-outline</v-icon
                                >
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="$store.state['user'].role == 'Admin'">
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="deleteSalaryMonth(item)"
                                    color="error"
                                    v-on="on"
                                    >mdi-delete-circle-outline</v-icon
                                >
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import utility from '../../mixins/utility.mixin';
export default {
    name: "Months",
    mixins: [utility],
    data() {
        return {
            salaryMonthHeaders: [
                { text: "Name", value: "name" },
                { text: "From", value: "date_from" },
                { text: "To", value: "date_to" },
                { text: "Action", value: "action" },
            ],
            searchSalaryMonth: "",

            month: {
                month: '',
                name: "",
                date_from: "",
                date_to: "",
            },

            editId: null,
            validation_errors: {},
            loading: false,
            menu: false,
            menu2: false,
            menu3: false,
        };
    },
    watch: {
        'month.month'(month){
            this.dateDistribute();
        }
    },
    created() {
        this.month.month = this.toISOLocal().substr(0, 7);
        this.dateDistribute();
        this.$store.dispatch("month/getSalaryMonths");
    },

    methods: {
        dateDistribute(){
            const date = new Date(this.month.month);
            const year = date.getFullYear();
            const month = date.getMonth();

            this.month.name = this.monthNameArray()[month] + ' - ' + year;
            this.month.date_from = this.toISOLocal(new Date(year, month, 1)).substr(0, 10);
            this.month.date_to = this.toISOLocal(new Date(year, month + 1, 0)).substr(0, 10);
        },
        async saveMonth() {
            if (!this.$refs.monthForm.validate()) return;

            this.loading = true;

            let data = {
                url: "/add-month",
                month: this.month,
            };


            if (this.editId) {
                data.url = "/update-month";
                data.month.id = this.editId;
            }

            let resObj = await this.$store.dispatch(
                "month/saveMonth",
                data
            );
            
             if(resObj.hasOwnProperty("validation_errors")){
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;

            if(resObj.isSuccess) {
                this.resetForm();
            }
        },
        editSalaryMonth(month) {
            this.editId          = month.id;

            this.month.name      = month.name;
            this.month.month     = month.month;
            this.month.date_from = month.date_from;
            this.month.date_to   = month.date_to; 
        },
        deleteSalaryMonth(month) {
            if (!confirm("Are you sure?")) return;

            this.$store.dispatch(
                "month/deleteSalaryMonth",
                month.id
            );
        },
        resetForm() {
            Object.keys(this.month).forEach(
                (k) => (this.month[k] = "")
            ); 
            this.month.month = this.toISOLocal().substr(0, 7);
            this.dateDistribute();
            this.editId = null;
            this.$refs.monthForm.resetValidation();
            this.validation_errors = {};
        },
    },
};
</script>

<style>
</style>
